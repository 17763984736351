<template>
  <div class="wrap">
    <!-- <head-block></head-block> -->
    <div class="warpInner">
      <router-view/>
    </div>
    <foot-block></foot-block>
  </div>
</template>

<script>
  // import HeadBlock from "../HeadBlock/HeadBlock"
  import FootBlock from "../FootBlock/FootBlock"

  export default {
    name: "Wrap",
    components: {
      // "head-block": HeadBlock,
      "foot-block": FootBlock
    },
  }
</script>

<style scoped lang="scss">
  @media screen and (min-width: $minDeviceWidth_pc) {
    @import "wrap_pc";
  }
  @media screen and (max-width: $minDeviceWidth_pc) {
    @import "wrap_mobile";
  }
</style>
