// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import "./assets/fonts/fonts.css"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { useRem, getDevice } from "../src/libs/base";

Vue.use(ElementUI)
if (getDevice() == 'mobile') {
  useRem(375)

}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
