import Vue from 'vue'
import Router from 'vue-router'
import Wrap from "../components/Wrap/Wrap"
import ArticleList from '../view/articleList/articleList'
import Diet from '../view/diet/diet'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: '_home',
      redirect: '/home',
      component: Wrap,
      children: [
        {
          path: '/home',
          name: 'home',
          meta: {
            title: '首页'
          },
          component: () => import('../view/home/home')
        },
        {
          path: '/diet',
          name: 'diet',
          component: ()=>import('../view/diet/diet'),
        }
      ]
    },
    {
      path: '/articleList',
      name: 'articleList',
      component: ArticleList,
    },
    
  ]
})
