<template>
  <div class="diet-box">
    <img class="header-box" :src="device == 'pc' ? headerPC : headerMobile" alt="" />
    <img v-if="device != 'pc'" class="header-box" src="../../assets/image/mobile/diet/content01text.png" />
    <img class="header-box" :src="device == 'pc' ? contentPC01 : contentMobile01" />
    <img class="header-box" :src="device == 'pc' ? contentPC02 : contentMobile02" />
    <img class="header-box" :src="device == 'pc' ? contentPC03 : contentMobile03" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ImageBox from "../../components/ImageBox/ImageBox";
import { getDevice } from "../../libs/base";
export default {
    name: "diet",
    components: {
        "image-box": ImageBox,
    },
    data(){
        return {
            device: null,
            headerPC:require('../../assets/image/pc/diet/header.png'),
            headerMobile:require('../../assets/image/mobile/diet/header.png'),
            contentPC01:require('../../assets/image/pc/diet/content01.png'),
            contentPC02:require('../../assets/image/pc/diet/content02.png'),
            contentPC03:require('../../assets/image/pc/diet/content03.png'),
            contentMobile01:require('../../assets/image/mobile/diet/content01.png'),
            contentMobile02:require('../../assets/image/mobile/diet/content02.png'),
            contentMobile03:require('../../assets/image/mobile/diet/content03.png'),
        }
    },
    created() {
        this.device = getDevice();
    },
}
</script>

<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
    @import "diet_pc";
}

@media screen and (max-width: $minDeviceWidth_pc) {
    @import "diet_mobile";
}
</style>