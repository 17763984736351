<template>
  <div class="footBlock">
    <div class="contactBlock">
      <div class="contactBlock-content">
        <div v-if="device == 'pc'" class="logoBox">
          <div class="img-box">
            <a href="https://www.recoveryplus.health/">
              <img class="usaOffice" :src="pcRplusLogo" alt="">
            </a>
          </div>
          <div class="split-line"></div>
          <a href="https://www.postop.cn/#/home">
            <img class="chinaOffice" :src="pcChinaLogo" alt="">
          </a>
        </div>
        <div class="logoBox" v-else>
          <a href="https://www.recoveryplus.health/">
            <div class="logo-content">
              <img src="../../assets/image/mobile/footer/USA-R.png" alt="">
              <div>
                <div class="logo-name">R Plus USA</div>
                <div class="logo-area">NY，USA</div>
              </div>
            </div>
          </a>
          <div class="split-line"></div>

          <a href="https://www.postop.cn/#/home">
            <div class="logo-content">
              <img src="../../assets/image/mobile/footer/RPlus.png" alt="">
              <div>
                <div class="logo-name">R Plus China</div>
                <div class="logo-area">CHENGDU，CHINA</div>
              </div>
            </div>
          </a>

        </div>
        <div class="appLogo-box">
          <a href="https://www.facebook.com/RecoveryPlus.health">
            <img :src="device == 'pc' ? pcFacebook : mobileFacebook" alt="">
          </a>
          <a href="https://www.instagram.com/recoveryplus.health/">
            <img :src="device == 'pc' ? pcIns : mobileIns" alt="">
          </a>
          <a href="https://twitter.com/recoveryplus_h?s=21">
            <img :src="device == 'pc' ? pcTwitter : mobileTwitter" alt="">
          </a>
          <a href="https://www.linkedin.com/company/recovery-plus-h">
            <img :src="device == 'pc' ? pcIn : mobileIn" alt="">
          </a>
          <a href="https://www.youtube.com/channel/UCI2Qir_opbytfE1dOmbLUIg">
            <img :src="device == 'pc' ? pcYouTube : mobileYouTube" alt="">
          </a>
        </div>
        <div class="copyright-box textStyle">&copy; 2023 Recovery Plus, Inc.</div>
      </div>


    </div>

  </div>
</template>

<script>
import { windowResize, getDevice } from "../../libs/base";

export default {
  name: "FootBlock",
  data() {
    return {
      pcRplusLogo: require('../../assets/image/pc/footer/USA.png'),
      mobileRplusLogo: require('../../assets/image/mobile/footer/USA.png'),
      pcChinaLogo: require('../../assets/image/pc/footer/China.png'),
      mobileChinaLogo: require('../../assets/image/mobile/footer/China.png'),

      pcFacebook: require('../../assets/image/pc/footer/facebook.png'),
      mobileFacebook: require('../../assets/image/mobile/footer/facebook.png'),

      pcIns: require('../../assets/image/pc/footer/ins.png'),
      mobileIns: require('../../assets/image/mobile/footer/ins.png'),

      pcTwitter: require('../../assets/image/pc/footer/Twitter.png'),
      mobileTwitter: require('../../assets/image/mobile/footer/Twitter.png'),

      pcIn: require('../../assets/image/pc/footer/in.png'),
      mobileIn: require('../../assets/image/mobile/footer/in.png'),

      pcYouTube: require('../../assets/image/pc/footer/YouTube.png'),
      mobileYouTube: require('../../assets/image/mobile/footer/YouTube.png'),
      device: null,
    }
  },
  created() {
    this.device = getDevice();

  },
  methods: {
    jump() {
      window.open("//beian.miit.gov.cn")
    },
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
  @import "./FootBlock_pc.scss";
}

@media screen and (max-width: $minDeviceWidth_pc) {
  @import "./FootBlock_mobile.scss";
}
</style>
