<template>
    <div class="articleList">
        <div class="header">
            <img src="../../assets/image/pc/articleList/header.jpg" alt=""/>
        </div>
        <div class="container">
            <div class="select-box">
                <span class="title">Select Year:</span>
                <el-select v-model="yearValue" clearable placeholder="select">
                    <el-option
                        v-for="item in yearList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="list-box">
                <div class="list-item" v-for="(item, i) in currentData" :key="i">
                    <div class="time">{{ item.time }}</div>
                    <div class="title">
                        <span @click="goToUrl(item)">{{ item.value }}</span>
                        <div @click="openPdf(item)" class="download-icon"></div>
                    </div>
                    <div class="subTitle">{{ item.subTitle }}</div>
                </div>

                <div class="page-box">
                    <el-pagination
                        background
                        @size-change="sizeChange"
                        @current-change="currentChange"
                        :current-page="page"
                        :page-size="size"
                        layout="prev, pager, next"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="appLogo-box">
                <a href="https://www.facebook.com/RecoveryPlus.health">
                    <img src="../../assets/image/pc/footer/facebook.png" alt=""/>
                </a>
                <a href="https://www.instagram.com/recoveryplus.health/">
                    <img src="../../assets/image/pc/footer/ins.png" alt=""/>
                </a>
                <a href="https://twitter.com/recoveryplus_h?s=21">
                    <img src="../../assets/image/pc/footer/Twitter.png" alt=""/>
                </a>
                <a href="https://www.linkedin.com/company/recovery-plus-h">
                    <img src="../../assets/image/pc/footer/in.png" alt=""/>
                </a>
                <a href="https://www.youtube.com/channel/UCI2Qir_opbytfE1dOmbLUIg">
                    <img src="../../assets/image/pc/footer/YouTube.png" alt=""/>
                </a>
            </div>
            <div class="textStyle">&copy; 2023 Recovery Plus, Inc.</div>
            <!-- <img src="../../assets/image/pc/articleList/footer.jpg" alt="" /> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import jsonData from "../../libs/data.json"

export default {
    data() {
        return {
            yearValue: "",
            yearList: [],
            articleListData: [], //全部数据
            currentData: [], //当前页数展示数据
            page: 1, //第几页
            size: 7, //一页多少条
            total: 0, //总条目数
        };
    },
    watch: {
        yearValue: {
            deep: true,
            handler(n) {
                // console.log(n);
                if (n && n != "") {
                    let newArr = [];
                    this.articleListData.forEach((item) => {
                        if (item.year == n) {
                            newArr.push(item);
                        }
                    });
                    this.getTabelData(newArr);
                    // console.log(newArr);
                } else if (n == "") {
                    this.getTabelData();
                }
            },
        },
    },
    created() {
        this.reqGetList();
        // this.getTabelData();
    },

    methods: {
        reqGetList() {
            axios
                .get("https://www.rplushealth.com/download/data.json")
                .then((res) => {
                    this.articleListData = res.data.data.list;
                    //this.articleListData = jsonData.data.list

                    let yearList = []
                    for (let item of this.articleListData) {
                        if (!yearList.includes(item.year)) {
                            yearList.push(item.year)
                        }
                    }
                    yearList.sort(function (a, b) {
                        return b - a
                    })
                    for (let item of yearList) {
                        this.yearList.push(
                            {
                                value: item,
                                label: item,
                            }
                        )
                    }
                    this.getTabelData()
                });
        },

        getTabelData(arr) {
            //allData为全部数据
            let currentArr = arr || this.articleListData;
            this.currentData = currentArr.slice(
                (this.page - 1) * this.size,
                this.page * this.size
            );
            this.total = currentArr.length;
        },

        currentChange(val) {
            // console.log("翻页，当前为第几页", val);
            this.page = val;
            this.getTabelData();
        },

        sizeChange(val) {
            this.size = val;
            this.page = 1;
            this.getTabelData();
        },
        goToUrl(item) {
            window.open(item.url, "_blank");
        },

        openPdf(item) {
            window.open(item.src, "_blank");
        },
    },
};
</script>


<style lang="scss" scoped>
.articleList {
    min-width: $minDeviceWidth_pc;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    //   position: fixed;
    display: flex;
    flex-direction: column;

    .header {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }

    .container {
        // flex: 1;
        width: 1200px;
        margin: 0 auto;
        overflow-y: auto;

        .select-box {
            display: flex;
            align-items: center;
            height: 70px;
            margin-top: 30px;

            .title {
                margin-right: 20px;
                font-family: "HarmonyOS_Sans_Regular";
                font-size: 35px;
                color: #555;
            }

            .el-select {
                width: 100px;
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 337px;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../assets/image/pc/articleList/footer.jpg");

        .appLogo-box {
            width: 146px;
            display: flex;
            margin: 0 auto 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                // width: 20px;
                // height: 20px;
                margin-right: 10px;
                cursor: pointer;
            }
        }

        .textStyle {
            text-align: center;
            margin-top: 10px;
            font-size: 22px;
            color: #fff;
            font-family: "HarmonyOS_Sans_Light";
        }
    }

    .list-box {
        .list-item {
            margin-top: 70px;
            position: relative;

            .time {
                margin-bottom: 20px;
                color: #244190;
                font-size: 30px;
                font-family: "HarmonyOS_Sans_Regular";
            }

            .title {
                line-height: 40px;

                span {
                    font-size: 35px;
                    color: #555555;
                    font-family: "HarmonyOS_Sans_Naskh_Arabic_UI_Thin";
                    cursor: pointer;

                    &:hover {
                        color: #244190;
                    }
                }

                .download-icon {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 30px;
                    height: 30px;
                    background-size: auto 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url("../../assets/image/pc/articleList/download.jpg");
                    cursor: pointer;

                    &:hover {
                        background-image: url("../../assets/image/pc/articleList/download-active.jpg");
                    }
                }
            }

            .subTitle {
                font-size: 28px;
                color: #aaa9a9;
                margin-top: 10px;
                font-family: "PingFang_Light";
            }
        }
    }

    .page-box {
        display: flex;
        justify-content: center;
        margin: 100px 0;
    }

    ::v-deep .el-select .el-input__inner:focus {
        border-color: #244190;
    }

    ::v-deep .el-input.is-focus .el-input__inner {
        caret-color: #244190;
        border: 1px solid #244190 !important;
    }

    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #244190;
    }
}
</style>